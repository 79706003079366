var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information
    ? _c(
        "section",
        [
          _vm.showError
            ? _c("b-alert", { attrs: { variant: "danger", show: "" } }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("ERROR.NOT_CORRECT_LINK")) + "\n  "
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }