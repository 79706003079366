<template>
  <section v-if="user.information">
    <b-alert variant="danger" show v-if="showError">
        {{ $t('ERROR.NOT_CORRECT_LINK') }}
    </b-alert>
  </section>
</template>
<script>
export default {
  props: ["message_uuid", "user_uuid"],
  components: {
  },
  data() {
    return {
      info: null,
      loading: false,
      canRedirect: false,
      showError: null
    };
  },
  methods: {
    async init()
    {
      await this.$store.dispatch("user/fetchUser");
      this.info = this.$store.state.user.information;
      this.loading = true;
      if(this.user.information == null)
      {  
        await this.$store.dispatch("user/setRedirectUrl", window.location.href);        
        this.$router.push({ name: 'Login' }).catch(() => {});
      }else{
        let self = this;
        this.$http
          .post(
            this.user.hostname + "/message/is-creator",
            {
              message_uuid: this.message_uuid,
              user_uuid: this.user_uuid
            }
          )
          .then(function(result) {
            let email = self.$store.state.user.information.email;
            let functionbox_uuid  = '';
            if(self.user.information.user_uuid == self.user_uuid)
            {
              self.canRedirect = true;
            }else{
              let functionboxes = self.user.functionboxes.items.filter(function(item) {
                return item.functionbox_uuid == self.user_uuid;
              });
              if(functionboxes.length == 1){
                functionbox_uuid = functionboxes[0].functionbox_uuid;
                email = functionboxes[0].email;
                self.canRedirect = true;
              }
            }
            if(self.canRedirect)
            {
              if(result.data.creator == true)
              {
                if(self.isMessage(result.data.type))
                {
                  if(functionbox_uuid == "")
                  {
                    self.$router.push({ name: 'Messages.Sent', params: { view_message_uuid: self.message_uuid, email: email } }).catch(() => {});      
                  }else{
                    self.$router.push({ name: 'Messages.Sent',  query: { functionbox_uuid: functionbox_uuid }, params: { view_message_uuid: self.message_uuid, email: email } }).catch(() => {});
                  }
                }else{
                  if(functionbox_uuid == "")
                  {
                    self.$router.push({ name: 'Meetings.Calendar', params: { view_message_uuid: self.message_uuid, email: email } }).catch(() => {});    
                  }else{
                    self.$router.push({ name: 'Meetings.Calendar',  query: { functionbox_uuid: functionbox_uuid }, params: { view_message_uuid: self.message_uuid, email: email } }).catch(() => {});
                  }
                }
              }else{
                if(self.isMessage(result.data.type))
                {
                  if(functionbox_uuid == "")
                  {
                    self.$router.push({ name: 'Messages.Inbox',  params: { view_message_uuid: self.message_uuid, email: email } }).catch(() => {});
                  }else{
                    self.$router.push({ name: 'Messages.Inbox',  query: { functionbox_uuid: functionbox_uuid }, params: { view_message_uuid: self.message_uuid, email: email } }).catch(() => {});
                  }
                }else{
                  self.showError = true;
                  self.loading = false;
                }
              }
            }else{
              self.showError = true;
              self.loading = false;
            }
          })
          .catch(function() {
          });  
      }
    },
    isMessage(message_type){      
      if (
        message_type == "teams-meeting" ||
        message_type == "jitsi-meeting"
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  watch: {
  },
  mounted: function() {
    //console.log("MOUNTED");
    this.init();
  },
};
</script>
<style></style>
